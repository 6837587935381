import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";

import { Button, SEO } from "components";

import VIDEO from "assets/404/video_01.mp4";

export default function NotFoundPage() {
  return (
    <>
      <SEO title="404: Page Not Found" />
      <main>
        <section className="wrapper default-grid gap-y-0 lg:pt-0 pt-10">
          <div className="lg:row-start-1 col-span-full lg:col-span-4 lg:col-start-9 row-span-1 row-start-2">
            <div className="lg:ml-0 lg:pt-26 sm:-mx-fluidMargin xl:-mr-26 relative -mx-6">
              <div className="aspect-h-7 aspect-w-5">
                <StaticImage
                  className="object-cover object-center"
                  src="../assets/404/placeholder_01.jpg"
                  placeholder="blurred"
                  alt="A machine making foods"
                  style={{ position: "absolute" }}
                />
                <LazyLoad
                  classNamePrefix="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                  once
                >
                  <video
                    className="absolute top-0 bottom-0 left-0 right-0 object-cover object-center w-full h-full"
                    preload="none"
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source src={VIDEO} type="video/mp4" />
                  </video>
                </LazyLoad>
                <div>
                  <div
                    className="translate-y-14 bg-mineralGray absolute bottom-0 right-0 w-px h-full origin-left transform translate-x-px"
                    style={{ height: "calc(100% + 56px)" }}
                  />
                  <div
                    className="translate-x-14 bg-mineralGray absolute bottom-0 right-0 h-px origin-left transform translate-y-1/2"
                    style={{ width: "calc(50% + 56px)" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="lg:pb-26 col-span-full lg:col-span-6 lg:col-start-4 relative self-center order-first row-span-1 row-start-1 space-y-6 text-center">
            <div className="space-y-4">
              <h1 className="h1 text-darkPurple">Oops!</h1>
              <p className="lead-bold text-mineralGray">
                We can’t seem to find the
                <br /> page you’re looking for.
              </p>
            </div>
            <Button label="Hearthside home" href="/" appearance="primary" />
          </div>
        </section>
      </main>
    </>
  );
}
